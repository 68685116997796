import styled from 'styled-components';

const Label = styled(({ color, ...rest }) => <div {...rest} />)`
  background-color: ${({ theme, color }) => theme.colors[color]};
  border-radius: 0.25em;
  padding: 0.5em 0.7em;
  font-size: 0.85em;
  font-weight: 600;
`;

export default Label;
